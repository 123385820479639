<template>
  <v-container>
    <v-row class="elevation-1">
      <v-col cols="6" lg="12">
        <v-btn
          id="create-new-fiscal-year"
          class="mx-2" color="primary"
          @click="openDialogbox"
        >
          Create New Fiscal Year
        </v-btn>
        <MiscCreateFiscalYear
          :dialog="dialog"
          @dialogVal="setDialog"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MiscCreateFiscalYear from '../../../components/Misc/CreateFiscalYear/MiscCreateFiscalYear.vue';

export default {
  name: 'MiscellaneousCreateFiscalYear',
  components: {
    MiscCreateFiscalYear,
  },
  data: () => ({
    dialog: false,
  }),
  created() {},
  computed: {
  },
  methods: {
    openDialogbox() {
      this.dialog = true;
    },
    setDialog(val) {
      this.dialog = val;
    },
  },
};
</script>
